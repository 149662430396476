html,
body {
  background-color: #f7f6f6;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

input,
textarea,
button,
a {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.hidden-xs {
  display: none !important;
}
.visible-xs {
  display: block !important;
}

@media print {
  .visible-xs {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .hidden-xs {
    display: block !important;
  }

  .visible-xs {
    display: none !important;
  }
}

.pageWrapper .heightFix {
  min-height: calc(100% - 420px);
}

.Toastify {
  font-size: 20px;
}
.Toastify .Toastify__toast-container {
  z-index: 99999;
}

#root,
.pageWrapper {
  height: 100%;
}

html > body .Toastify__toast-body {
  margin: auto 1.2rem;
}
